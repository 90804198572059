/**
 * Module dependencies
 */
import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';

import { LazyHydrate as Hydrator, loadable } from 'nordic/lazy';
import classnames from 'classnames';

import Search from './main';
import Toolbar from '../../../../../components/toolbar/chunk-toolbar.main.mobile';
import { ChunkAppliedFiltersContainer } from '../../../../../components/filters/applied/chunk-applied-filters.mobile';
import { PaginationMobile } from '../../../../../components-v2/pagination/index';
import RelatedSearches from '../../../../../components-v2/related-searches/chunk-related-searches.mobile';
import TopKeywords from '../../../../../components/top-keywords/chunk-top-keywords.mobile';
import CrossSiteModal from '../../../../../components/cross-site-modal/chunk-cross-site';
import Header from '../../../../../components-v2/header';
import Billboard from '../../../../../components/billboard/chunk-billboard.mobile';
import ListingDisclaimer from '../../../../../components/listing-disclaimer/chunk-listing-disclaimer.mobile';
import FooterDisclaimer from '../../../../../components-v2/footer-disclaimer/chunk-footer-disclaimer';
import BreadcrumbMobile from '../../../../../components-v2/sidebar/components/breadcrumb';
import SnackBar from '../../../../../components/snackbar/container';
import FullFreeshippingSwitcher from '../../../../../components/full-snackbar/full-freeshipping-switcher';
import TopSwitchFilters from '../../../../../components/top-switch-filter/chunk-top-switch-filters';
import PricingBanner from '../../../../../components-v2/pricing-banner/chunk-pricing-banner';
import BoletoDiscountMessage from '../../../../../components/boleto-discount-message/chunk-discount-message';
import ValuePropositionsDetails from '../../../../../components/value-propositions-details/chunk-propositions-details';
import CoachMark from '../../../../../components/coachmark/chunk-coachmark';
import { trackSellerPixelsInfo } from '../../../../../lib/tracking';
import { HEADER_TYPES } from '../../../../../components-v2/header/utils/constants';
import { LAYOUTS, VERTICAL_TYPE } from '../../../../../constants';
import { LANDING_TYPES } from './constants';
import { EshopMobile } from '../../../../../components-v2/eshops/mobile';
import StickyComponents from '../../../../../components/sticky-components/sticky-components';
import AdvertisingAdn from '../../../../../components/advertising-adn/chunk-advertising-adn.mobile';
import SearchSave from '../../../../../components/alert-v2/chunk-search-save.mobile';
import { LayoutManager } from '../../../../../components-v2/layout/layout.manager';

/* eslint-disable @typescript-eslint/promise-function-async */
const CPGMobile = loadable(() => import('../../../../../components/cpg-nordic/mobile'));
const CarouselDraggable = loadable(() => import('../../../../../components/carousel-search/chunk-carousel-draggable'));
const MainHeader = loadable(() => import('../../../../../components/main-header/chunk-main-header.mobile'));
const Alert = loadable(() => import('../../../../../components/alert/chunk-alert.mobile'));

const EshopsFreeshippingContainer = loadable(() =>
  import('../../../../../components/full-snackbar/eshops-freeshipping.desktop'),
);
const FullShipping = loadable(() => import('../../../../../components/full-snackbar/full-freeshipping.mobile'));
const ZrpDisclaimer = loadable(() => import('../../../../../components/zrp-disclaimer/zrp-disclaimer'));
const FooterComponents = loadable(() => import('../../../../../components-v2/footer-components'));
/* eslint-enable @typescript-eslint/promise-function-async */

const SearchMobile = (props) => {
  const { initialState } = props;
  const layout = initialState.layout_options.current;
  const { seo, shops, vertical, landing, header, carousel } = initialState;
  const className = classnames({
    'ui-search-applied-filters--no-header': !!shops,
  });
  const landingType = landing?.type;
  const headerType = header?.type;

  useEffect(() => {
    if (shops && window.tracking_mediator) {
      trackSellerPixelsInfo(window.tracking_mediator, initialState.results);
    }
  }, [initialState.results, shops]);

  return (
    <Search {...props} platformId="STD">
      <Hydrator whenVisible>
        {vertical !== VERTICAL_TYPE.RES && headerType !== HEADER_TYPES.SELLER && <Header />}
      </Hydrator>
      <FullFreeshippingSwitcher
        eshopsComponent={<EshopsFreeshippingContainer />}
        defaultComponent={<FullShipping disabledByCookie={props.fullFreeShippingDisabled} />}
      />

      {props.initialState.cpg_header && <CPGMobile {...props.initialState.cpg_header} />}
      <Hydrator whenVisible>
        <Toolbar />
      </Hydrator>
      <Hydrator whenVisible>
        {vertical === VERTICAL_TYPE.RES &&
          (landingType === LANDING_TYPES.OFFICIAL_STORE || layout === LAYOUTS.TYPE_MAP) && <Header />}
      </Hydrator>
      <Hydrator whenVisible>
        <TopSwitchFilters />
      </Hydrator>
      <Hydrator whenVisible>
        <StickyComponents />
      </Hydrator>

      <Hydrator whenVisible>
        <CoachMark />
      </Hydrator>
      <EshopMobile>
        {!shops && (
          <Hydrator ssrOnly>
            <MainHeader
              h1={seo.h1}
              linkH1={seo.link_h1}
              subtitle={seo.subtitle}
              totalResults={seo.total_results}
              values={seo.values}
            />
          </Hydrator>
        )}
        <Hydrator ssrOnly>
          <ChunkAppliedFiltersContainer className={className} />
        </Hydrator>
        {headerType === HEADER_TYPES.SELLER && <Header />}
        <Hydrator whenVisible>
          <Billboard />
        </Hydrator>
        <Hydrator whenVisible>{carousel && <CarouselDraggable />}</Hydrator>
        <Hydrator whenVisible>
          <ZrpDisclaimer />
        </Hydrator>
        <Hydrator ssrOnly>
          <PricingBanner />
        </Hydrator>
        <Hydrator whenVisible>
          <BoletoDiscountMessage />
        </Hydrator>
      </EshopMobile>
      <Hydrator whenVisible>
        <LayoutManager />
      </Hydrator>
      <Hydrator whenVisible>
        {'alert' in initialState ? <Alert /> : 'alert_component' in initialState && <SearchSave />}
      </Hydrator>
      <Hydrator ssrOnly>
        <ListingDisclaimer />
      </Hydrator>
      <SnackBar />
      <PaginationMobile />
      <Hydrator ssrOnly>
        <AdvertisingAdn />
      </Hydrator>
      <Hydrator whenVisible>
        <FooterComponents />
      </Hydrator>
      <Hydrator ssrOnly>
        <FooterDisclaimer />
      </Hydrator>
      <Hydrator ssrOnly>
        <RelatedSearches />
      </Hydrator>
      <Hydrator ssrOnly>
        <TopKeywords />
      </Hydrator>
      {!shops && <BreadcrumbMobile nodes={initialState?.breadcrumb} isMobile />}
      <Hydrator whenVisible>
        <CrossSiteModal />
      </Hydrator>
      <Hydrator whenVisible>
        <ValuePropositionsDetails />
      </Hydrator>
    </Search>
  );
};

SearchMobile.propTypes = {
  fullFreeShippingDisabled: string,
  initialState: shape({}),
  site: string,
};

SearchMobile.defaultProps = {
  fullFreeShippingDisabled: '',
  initialState: null,
};

export default SearchMobile;
